import { Link } from 'gatsby';
import React from 'react';

import SEO from '../components/common/seo';
import { Title } from '../components/common/Title';
import { Wrapper } from '../components/layout/Wrapper';

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not found" />
      <Wrapper padded>
        <Title>Sorry! Page not found</Title>
        <p>There is no page at this URL.</p>
        <p>
          Try going back to the <Link to="/">Home page</Link>, or maybe find something on the{' '}
          <Link to="/explore">Explore page</Link>
        </p>
      </Wrapper>
    </>
  );
};

export default NotFoundPage;
