import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon, iconKeys } from '../../common/Icon/Icon';

import styles from './Title.module.scss';

const Title = ({
  tagName: El = 'span',
  className,
  size = 'normal',
  bold = false,
  children,
  icon,
  center,
  ...props
}) => {
  return (
    <El
      className={classNames(
        styles.title,
        styles[size],
        { [styles.center]: center },
        { [styles.bold]: bold },
        { [styles.withIcon]: !!icon },
        className,
      )}
      {...props}
    >
      {icon && <Icon className={styles.icon} name={icon} />}
      {children}
    </El>
  );
};

Title.propTypes = {
  tagName: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  bold: PropTypes.bool,
  icon: PropTypes.oneOf(iconKeys),
};

export { Title };
